const leaflets = {
    "coop": {
        "config": {
            "primary-color": "rgb(0, 0, 0)",
            "secondary-color": "rgb(225, 8, 21)",
            "tertiary-color": "#ffdd00",
            "hover-color": "rgba(220, 35, 17, 0.5)",
            "primary-alpha-color": "rgba(35, 94, 157, 0.7)",
            "clientIcon": require("assets/images/logo_coop.png"),
            "serverPath": "democoop.volantinointerattivo.net.it/",
            "pdfPath": "/media/pdf/volantino.pdf",
            "shareFlyerTitle": "Coop | Volantino interattivo",
            "shareFlyerURL": "https://democoop.volantinointerattivo.net?v=326",
            "shareProductSentence": "Guarda il Volantino di Coop!",
            "ogTitleMeta": "Coop | Volantino interattivo",
            "ogDescriptionMeta": "Dai uno sguardo al volantino interattivo di Coop. Offerte valide dal 2 al 11 febbraio",
            "ogImageMeta": "https://democoop.volantinointerattivo.net/media/images/coop_fb.png",
            "filterBrand": 0,
            "filterUnderprice": 0,
            "brandImage": "",
            "underPriceImage": "",
            "ga_active": false,
            "ga_tracking_id": "UA-152069090-",
            "release_id": "1",
            "send_grocery_list_to_market": false,
            "grocery_list_min_value": 20,
            "client_id": 29,
            "signboard_id": 46,
            "has_pages": true,
            "hide_grocery_list": false,
            "hide_searchbar": false,
            "line_through": false,
            "category_banner": "/media/images/banner.jpg",
            "category_banner_mobile": "/media/images/banner_mobile.jpg",
            "hide_plus_product": false,
            "hide_plus_product_price": false,
            "type": "leaflet",
            "product_banner": "",
            "href_banner": "",
            "internal_banner_click": false,
            "external_banner_click": false,
            "add_icon_list": true,
            "version": 326
        },
        "leaflet": {
            "name": "Demo COOP",
            "code": "326",
            "id_campaign": "326",
            "index": {
                "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/Indice-coop-DEMO_b085NCc.png",
                "links": [
                    {
                        "page": 2,
                        "blueprint": {
                            "top": 31.0518574488379,
                            "left": 11.855920119512652,
                            "width": 75.80350428989955,
                            "height": 7.511659497825634
                        }
                    },
                    {
                        "page": 4,
                        "blueprint": {
                            "top": 38.42920454512626,
                            "left": 11.456510416666667,
                            "width": 75.80350428989955,
                            "height": 7.511659497825634
                        }
                    },
                    {
                        "page": 6,
                        "blueprint": {
                            "top": 45.68993183682823,
                            "left": 11.88794904436384,
                            "width": 75.80350428989955,
                            "height": 7.511659497825634
                        }
                    },
                    {
                        "page": 8,
                        "blueprint": {
                            "top": 53.20209342837112,
                            "left": 11.616684105282738,
                            "width": 75.80350428989955,
                            "height": 7.511659497825634
                        }
                    },
                    {
                        "page": 10,
                        "blueprint": {
                            "top": 60.39379976099314,
                            "left": 11.624429757254466,
                            "width": 75.80350428989955,
                            "height": 7.511659497825634
                        }
                    },
                    {
                        "page": 12,
                        "blueprint": {
                            "top": 67.70963022069131,
                            "left": 11.312859816778277,
                            "width": 75.80350428989955,
                            "height": 7.511659497825634
                        }
                    }
                ]
            },
            "categories": [
                {
                    "category_name": "Self Service",
                    "subcategories": [
                        "Ittico",
                        "Yogurt",
                        "Pasta",
                        "Formaggi",
                        "Salumi"
                    ]
                },
                {
                    "category_name": "Alimentari",
                    "subcategories": [
                        "Dispensa",
                        "Pasta"
                    ]
                },
                {
                    "category_name": "Surgelati",
                    "subcategories": [
                        "Ittico"
                    ]
                },
                {
                    "category_name": "Gastronomia",
                    "subcategories": [
                        "Salumi"
                    ]
                }
            ],
            "pages": [
                {
                    "number": 1,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/PAG3_h4VhMJC.jpg",
                    "products": [
                        {
                            "item_id": "cstm61962236",
                            "field1": "filetto di salmone",
                            "field2": "Rio mare",
                            "field3": "",
                            "field4": "",
                            "description": "filetto di salmone Rio mare",
                            "grammage": null,
                            "price": "2.89",
                            "price_label": "€ 2,89",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Al naturale",
                                "All'olio extra vergine"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 31.349879564488404,
                                "left": 0.0,
                                "width": 27.75934529711433,
                                "height": 34.27944987617717
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/1_ConjqgK.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/2_ouLQpnd.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_kMlAVUz_BzH2JS8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "filetto di salmone",
                                        "testo": "Con il Filetto di Salmone all’Olio Extra Vergine di Oliva Rio Mare porti in tavola il gusto unico del miglior filetto di salmone, ben pulito da pelle e lische, semplicemente cotto al vapore, per un piatto dal gusto autentico e delicato.\nPERCHÉ:\nIl nuovo Salmone Rio Mare, delicatamente cotto al vapore e senza conservanti, contiene solo la giusta quantità di Olio Extra Vergine di Oliva ed è privo di pelle e lische. La sua carne dal colore rosato è ricca di Omega-3, fonte di proteine nobili, fosforo, iodio, vitamina B12 e vitamina D. (Fonte: riomare.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/alOjNvUnCW/images/filettidisalmone_Evo_alvapore.webp"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm08138331",
                            "field1": "ragù contadino",
                            "field2": "barilla",
                            "field3": "",
                            "field4": "",
                            "description": "ragù contadino barilla",
                            "grammage": null,
                            "price": "3.55",
                            "price_label": "€ 3,55",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 30.217083805675728,
                                "left": 27.516213020975794,
                                "width": 24.14923872999863,
                                "height": 34.65704615776919
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/3_T5m297d.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_rwmfHlg_GAg5Ym8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.barilla.com/it-it/prodotti/sughi/ragu-contadino"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm56590678",
                            "field1": "jamonilla",
                            "field2": "tulip",
                            "field3": "prosciutto e carne suina",
                            "field4": "",
                            "description": "jamonilla tulip prosciutto e carne suina",
                            "grammage": null,
                            "price": "2.69",
                            "price_label": "€ 2,69",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 65.62932944066559,
                                "left": 27.75934529711433,
                                "width": 23.90610645386009,
                                "height": 31.80030387356854
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Tulip_Jamonilla_340g_mock_up_IT_2_1_1558026340_jQRqcYe.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_b1VkzLu_MM5ygre.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "jamonilla",
                                    "data": {
                                        "specifiche": "Valori medi per 100 g di prodotto:\nEnergia 1206Kj/291Kcal, Grassi 25g, di cui acidi grassi saturi 9.5g, Carboidrati 1.5g, di cui zuccheri 0g, Proteine 15g, Sale 3g (Fonte: tulipfood.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/alOjNvUnCW/images/jamonilla340.webp"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm38092686",
                            "field1": "fruttolo smarties",
                            "field2": "",
                            "field3": "nesquick chocoballs",
                            "field4": "",
                            "description": "fruttolo smarties nesquick chocoballs",
                            "grammage": null,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fruttolo smarties",
                                "Nesquick chocoballs"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 65.5034594041105,
                                "left": 1.0940919037199124,
                                "width": 26.90838566953295,
                                "height": 31.926173910123627
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/NESQUIK_CHOCOBALLS_GR_120_1547477561_1574506695_1592472016_9EO1n5v.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/4_Bh2iVbO.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_SCeab8z_jcDDY1C.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm94546369",
                            "field1": "emiliane",
                            "field2": "barilla",
                            "field3": "tagliatelle/ pappardelle",
                            "field4": "",
                            "description": "emiliane barilla tagliatelle/ pappardelle",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Tagliatelle",
                                "Pappardelle"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 37.918422792018816,
                                "left": 52.79753369888539,
                                "width": 46.4381245085134,
                                "height": 53.2414006641959
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Pappardelle_500g_1535982202_1ia9DL8.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Tagliatelle_500g_1535982203_d0MV63F.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_76JSDGQ_76q6Wp0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/video/Barilla_Emiliane__Tagliatelle_asparagi_e_culatello_-_Natale.mp4"
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 2,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/pagine_skMjmOO.jpg",
                    "products": [
                        {
                            "item_id": "cstm01555924",
                            "field1": "gusto di una volta",
                            "field2": "granarolo",
                            "field3": "ricotta vaccina",
                            "field4": "",
                            "description": "gusto di una volta granarolo ricotta vaccina",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 32.50617370001066,
                                "left": 2.2261705127273657,
                                "width": 47.53221975113682,
                                "height": 23.662844355529483
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/6001868_GranaroloRicottaFresca250_1562658229_1597138971_54wNY1i.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_e3MIU9i_HNLfJW4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm49399221",
                            "field1": "simmenthal",
                            "field2": "",
                            "field3": "con peperoncino",
                            "field4": "",
                            "description": "simmenthal con peperoncino",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 55.53968861487457,
                                "left": 2.2261705127273657,
                                "width": 47.53221975113682,
                                "height": 18.628195002131662
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/principale_8004030921115_1590768422_1590769814_kPY6We9.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_g4tQOwj_tgl1ogl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm72769093",
                            "field1": "filetto di salmone",
                            "field2": "rio mare",
                            "field3": "",
                            "field4": "",
                            "description": "filetto di salmone rio mare",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Al naturale",
                                "All'olio extra vergine"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 0.37759801010117516,
                                "left": 50.36622429311405,
                                "width": 24.313154157976612,
                                "height": 25.83526745518052
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/1_6okhkKq_mCJGe1V.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/2_JmrxrVt_2kNdXZn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_ollFXCm_v6diJHE.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "filetto di salmone",
                                        "testo": "Con il Filetto di Salmone all’Olio Extra Vergine di Oliva Rio Mare porti in tavola il gusto unico del miglior filetto di salmone, ben pulito da pelle e lische, semplicemente cotto al vapore, per un piatto dal gusto autentico e delicato.\nPERCHÉ:\nIl nuovo Salmone Rio Mare, delicatamente cotto al vapore e senza conservanti, contiene solo la giusta quantità di Olio Extra Vergine di Oliva ed è privo di pelle e lische. La sua carne dal colore rosato è ricca di Omega-3, fonte di proteine nobili, fosforo, iodio, vitamina B12 e vitamina D. (Fonte: riomare.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/alOjNvUnCW/images/filettidisalmone_Evo_alvapore_HOIKPpd.webp"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm25543005",
                            "field1": "gnocchi",
                            "field2": "rana",
                            "field3": "",
                            "field4": "",
                            "description": "gnocchi rana",
                            "grammage": null,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 0.88106432824828,
                                "left": 74.67937845109067,
                                "width": 25.320621548909322,
                                "height": 25.331801137033416
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/rana_gnocchi_500_gr_1551255863_1564650849_1612284682_xAoyeIh.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_SfGTkBL_CINd5DP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "world",
                                    "title": "",
                                    "data": "https://www.giovannirana.it/prodotti/gnocchi/gnocchi-classici/gnocchi-di-patate"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm91340356",
                            "field1": "pasta",
                            "field2": "garofalo",
                            "field3": "",
                            "field4": "",
                            "description": "pasta garofalo",
                            "grammage": null,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 26.59046520389202,
                                "left": 74.67937845109067,
                                "width": 25.320621548909322,
                                "height": 23.881882810058883
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Garofalo_Calamarata_gr_500_1536941045_1537253332_1540376096_4jVXYN6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_kPWZz8a_4GmzQo4.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/video/Pasta_Garofalo.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm03579777",
                            "field1": "ragù contadino",
                            "field2": "barilla",
                            "field3": "",
                            "field4": "",
                            "description": "ragù contadino barilla",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 26.464598624355247,
                                "left": 50.1580369811611,
                                "width": 24.521341469929567,
                                "height": 24.007749389595663
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/3_KPxyjz5_qBgI9v5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_wZgGflQ_uWfwBoS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm20833895",
                            "field1": "jamonilla",
                            "field2": "tulip",
                            "field3": "prosciutto e carne suino",
                            "field4": "",
                            "description": "jamonilla tulip prosciutto e carne suino",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 50.97581433209801,
                                "left": 50.1580369811611,
                                "width": 24.64290426909532,
                                "height": 23.31793932146331
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Tulip_Jamonilla_340g_mock_up_IT_2_1_1558026340_FyphMUi_bIKnAlY.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_REye0TM_FXdLSzl.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "jamonilla",
                                    "data": {
                                        "specifiche": "Valori medi per 100 g di prodotto:\nEnergia 1206Kj/291Kcal, Grassi 25g, di cui acidi grassi saturi 9.5g, Carboidrati 1.5g, di cui zuccheri 0g, Proteine 15g, Sale 3g (Fonte: tulipfood.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/alOjNvUnCW/images/jamonilla340_2pg1PBd.webp"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm08139243",
                            "field1": "emiliane",
                            "field2": "barille",
                            "field3": "tagliette/ pappardelle",
                            "field4": "",
                            "description": "emiliane barille tagliette/ pappardelle",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Tagliatelle",
                                "Papparelle"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 50.59821113646938,
                                "left": 74.80094125025643,
                                "width": 24.955933151412058,
                                "height": 23.695542517091948
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Pappardelle_500g_1535982202_bLsT783_INFMuGj.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Tagliatelle_500g_1535982203_7Q9pI0y_lOMdfgp.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_JjLmtfl_rJea6g0.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/video/Barilla_Emiliane__Tagliatelle_asparagi_e_culatello_-_Natale_tnStVRF.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm30447654",
                            "field1": "gusto di una volta",
                            "field2": "granarolo",
                            "field3": "ricotta di vaccina",
                            "field4": "",
                            "description": "gusto di una volta granarolo ricotta di vaccina",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 74.79721997170842,
                                "left": 74.80094125025643,
                                "width": 24.955933151412058,
                                "height": 25.20278002829156
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/6001868_GranaroloRicottaFresca250_1562658229_1597138971_YrLEaZr_mnoUnHn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_uSDYomp_NT37A9n.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm00509853",
                            "field1": "hotdog classico",
                            "field2": "senfter",
                            "field3": "",
                            "field4": "",
                            "description": "hotdog classico senfter",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 74.9230900082635,
                                "left": 50.036474181995345,
                                "width": 24.76446706826108,
                                "height": 25.07690999173648
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Senfter_HotDog_classico_1569499105_1570122048_QLx5CaN.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_S6UvPIa_Hy9FB2d.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm68786998",
                            "field1": "Accattatevillo",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Accattatevillo",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 74.79721997170842,
                                "left": 25.323679984528855,
                                "width": 24.71279419746649,
                                "height": 25.20278002829156
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/5_zmEOqiZ.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/6_vCdcEuP.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_AVl3DoY_hgGAcqH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm23017955",
                            "field1": "simmenthal",
                            "field2": "",
                            "field3": "con peperoncino",
                            "field4": "",
                            "description": "simmenthal con peperoncino",
                            "grammage": null,
                            "price": "0.00",
                            "price_label": "",
                            "equivalence": null,
                            "quantity_step": null,
                            "grocery_label": "",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 75.04895313078197,
                                "left": 0.0,
                                "width": 25.323679984528855,
                                "height": 24.951046869218008
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/principale_8004030921115_1590768422_1590769814_JlrdPZW_yljwgX7.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_jjtI67H_tkL8POc.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 3,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/pagine2_LzqM7D9.jpg",
                    "products": [
                        {
                            "item_id": "cstm71354721",
                            "field1": "gusto di una volta",
                            "field2": "granarolo",
                            "field3": "ricotta vaccina",
                            "field4": "",
                            "description": "gusto di una volta granarolo ricotta vaccina",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Formaggi",
                            "blueprint": {
                                "top": 0.6293311691747276,
                                "left": 0.0,
                                "width": 25.080541030583287,
                                "height": 25.83526399816221
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/6001868_GranaroloRicottaFresca250_1562658229_1597138971_yyGmJzW_pGgEo5b.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_1WIYUXS_RWKCrpa.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm60665680",
                            "field1": "filetto di salmone",
                            "field2": "rio mare",
                            "field3": "",
                            "field4": "",
                            "description": "filetto di salmone rio mare",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Surgelati",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 0.7551960202023503,
                                "left": 25.080541030583287,
                                "width": 24.88299146522839,
                                "height": 25.709399147134587
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/2_XwRS9p2_H2nBHsl.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_lm1Qx7r_U1fmEev.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm53939406",
                            "field1": "hotdog classico",
                            "field2": "senfter",
                            "field3": "",
                            "field4": "",
                            "description": "hotdog classico senfter",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 27.219794644557595,
                                "left": 25.080541030583287,
                                "width": 24.88299146522839,
                                "height": 23.37841994893009
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Senfter_HotDog_classico_1569499105_1570122048_VjOQDiL_Mt7WZMG.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_kWzCqwk_LOnK86H.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm38763724",
                            "field1": "Accattatevillo",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Accattatevillo",
                            "grammage": null,
                            "price": "1.50",
                            "price_label": "€ 1,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 27.09392806502082,
                                "left": 0.0,
                                "width": 25.080541030583287,
                                "height": 23.504286528466864
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/6_tBbHBxV_02fG5BP.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_kqPfSEf_AIsOsox.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm40354713",
                            "field1": "filetto di salmone",
                            "field2": "rio mare",
                            "field3": "",
                            "field4": "",
                            "description": "filetto di salmone rio mare",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 51.47927719322682,
                                "left": 0.0,
                                "width": 25.080541030583287,
                                "height": 22.8144764603345
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/1_peMpGyT_sn7qBl6.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_vYAMt7L_SnhgVfg.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "info",
                                    "title": "",
                                    "data": {
                                        "titolo": "filetto di salmone",
                                        "testo": "Con il Filetto di Salmone all’Olio Extra Vergine di Oliva Rio Mare porti in tavola il gusto unico del miglior filetto di salmone, ben pulito da pelle e lische, semplicemente cotto al vapore, per un piatto dal gusto autentico e delicato.\nPERCHÉ:\nIl nuovo Salmone Rio Mare, delicatamente cotto al vapore e senza conservanti, contiene solo la giusta quantità di Olio Extra Vergine di Oliva ed è privo di pelle e lische. La sua carne dal colore rosato è ricca di Omega-3, fonte di proteine nobili, fosforo, iodio, vitamina B12 e vitamina D. (Fonte: riomare.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/alOjNvUnCW/images/filettidisalmone_Evo_alvapore_BCjcgTO.webp"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm05971674",
                            "field1": "emiliane",
                            "field2": "barille",
                            "field3": "tagliatelle",
                            "field4": "",
                            "description": "emiliane barille tagliatelle",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Pasta",
                            "blueprint": {
                                "top": 50.84994775256124,
                                "left": 25.080541030583287,
                                "width": 24.761421988255606,
                                "height": 23.443805901000076
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Tagliatelle_500g_1535982203_hCo0E7f_ck33SQA.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_Yk10put_V2Q40IS.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/video/Barilla_Emiliane__Tagliatelle_asparagi_e_culatello_-_Natale_YxItK7Y.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm02260577",
                            "field1": "Accattatevillo",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "Accattatevillo",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 74.67135684918995,
                                "left": 0.0,
                                "width": 25.080541030583287,
                                "height": 25.328643150810034
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/5_l2rBG6b_svoMJbU.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_im9qNgz_fWo1vZP.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm53003030",
                            "field1": "jamonilla",
                            "field2": "tulip",
                            "field3": "prosciutto e carne suina",
                            "field4": "",
                            "description": "jamonilla tulip prosciutto e carne suina",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Alimentari",
                            "subcategory": "Dispensa",
                            "blueprint": {
                                "top": 74.67134993515334,
                                "left": 25.080541030583287,
                                "width": 24.882988126324875,
                                "height": 25.32865006484665
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/Tulip_Jamonilla_340g_mock_up_IT_2_1_1558026340_yoPmOKp_5IUdwyb.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_VZUkt40_55SgZuW.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "specs",
                                    "title": "jamonilla",
                                    "data": {
                                        "specifiche": "Valori medi per 100 g di prodotto:\nEnergia 1206Kj/291Kcal, Grassi 25g, di cui acidi grassi saturi 9.5g, Carboidrati 1.5g, di cui zuccheri 0g, Proteine 15g, Sale 3g (Fonte: tulipfood.it)",
                                        "img": [
                                            "https://interattivo.s3.amazonaws.com/alOjNvUnCW/images/jamonilla340_UomcIoi.webp"
                                        ]
                                    }
                                }
                            ]
                        },
                        {
                            "item_id": "cstm99714155",
                            "field1": "fruttolo con smarties",
                            "field2": "",
                            "field3": "nesquik chocoballs",
                            "field4": "",
                            "description": "fruttolo con smarties nesquik chocoballs",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Fruttolo con smarties",
                                "Nesquik chocoballs"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 74.54548681263485,
                                "left": 49.96352915690816,
                                "width": 24.715849294182505,
                                "height": 25.454513187365116
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/NESQUIK_CHOCOBALLS_GR_120_1547477561_1574506695_1592472016_C9uW9n6_TL6hdEC.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/4_J8ITnQg_dty6f8E.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_7PzzCUZ_zKkhGUf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm82501512",
                            "field1": "fruttolo",
                            "field2": "nestlè",
                            "field3": "galak/ fragola con smarties",
                            "field4": "",
                            "description": "fruttolo nestlè galak/ fragola con smarties",
                            "grammage": null,
                            "price": "3.50",
                            "price_label": "€ 3,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [
                                "Galak",
                                "Fragola con smarties"
                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 75.04894621674535,
                                "left": 74.67937845109067,
                                "width": 25.12611372465639,
                                "height": 24.951053783254626
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/7_oPfqZTn.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/8_jynp7yA.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_C77SEKI_oHVe2Pf.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                },
                                {
                                    "type": "play",
                                    "title": "",
                                    "data": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/video/Fr%C3%B9ttolo_Smarties_-_Una_merenda_divertente_golosa_e_colorata.mp4"
                                }
                            ]
                        },
                        {
                            "item_id": "cstm46657960",
                            "field1": "filetto di salmone ",
                            "field2": "rio mare",
                            "field3": "",
                            "field4": "",
                            "description": "filetto di salmone  rio mare",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Ittico",
                            "blueprint": {
                                "top": 53.177770693051514,
                                "left": 50.00152254000273,
                                "width": 47.858911426636006,
                                "height": 21.115982960509804
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/1_jnVV1qH_mLefJ6W.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_tBVNN0T_B0JFrIA.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm83777919",
                            "field1": "fruit passion",
                            "field2": "muller",
                            "field3": "kiwi",
                            "field4": "",
                            "description": "fruit passion muller kiwi",
                            "grammage": null,
                            "price": "4.50",
                            "price_label": "€ 4,50",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "pz.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Self Service",
                            "subcategory": "Yogurt",
                            "blueprint": {
                                "top": 32.883773438621,
                                "left": 50.00152254000273,
                                "width": 47.858911426636006,
                                "height": 19.538797777209865
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/9_ILUKJ1I.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_FApfakz_TPnjxa8.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 4,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/pg_10_SXWnPEq.jpg",
                    "products": [
                        {
                            "item_id": "cstm24956183",
                            "field1": "salame",
                            "field2": "golfetta",
                            "field3": "",
                            "field4": "",
                            "description": "salame golfetta",
                            "grammage": null,
                            "price": "45.00",
                            "price_label": "€ 45,00 al Kg",
                            "equivalence": 1000,
                            "quantity_step": 100,
                            "grocery_label": "gr.",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 75.15376831356609,
                                "left": 0.0,
                                "width": 25.323666628914797,
                                "height": 24.846231686433903
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/1_uoXKBhG_jGQi0B5.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_0lwjH9h_O6N9QRH.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        },
                        {
                            "item_id": "cstm61571006",
                            "field1": "mortadella",
                            "field2": "",
                            "field3": "",
                            "field4": "",
                            "description": "mortadella",
                            "grammage": null,
                            "price": "2.90",
                            "price_label": "€ 2,90 l'etto",
                            "equivalence": 1,
                            "quantity_step": 1,
                            "grocery_label": "etto/i",
                            "varieties": [

                            ],
                            "price_for_kg": null,
                            "available_pieces": null,
                            "max_purchasable_pieces": null,
                            "points": "",
                            "fidelity_product": false,
                            "focus": false,
                            "pam": false,
                            "three_for_two": false,
                            "one_and_one_gratis": false,
                            "underpriced_product": false,
                            "category": "Gastronomia",
                            "subcategory": "Salumi",
                            "blueprint": {
                                "top": 50.75644900456694,
                                "left": 0.0,
                                "width": 25.56679890505334,
                                "height": 23.314833716319075
                            },
                            "images": [
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/2_W4Etsbh_gIbxv4R.jpg",
                                    "cropped": false
                                },
                                {
                                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/products/cropped_image_NdqJnOz_EyTt8lN.png",
                                    "cropped": true
                                }
                            ],
                            "markers": [
                                {
                                    "type": "plus",
                                    "title": null,
                                    "data": ""
                                }
                            ]
                        }
                    ]
                },
                {
                    "number": 5,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_5_trSMzxV.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 6,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_6_AmNkpMa.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 7,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_7_RD0xhLE.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 8,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_8_RtF0ClQ.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 9,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_9_EzCByu0.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 10,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_10_oQfVEZo.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 11,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_11_lNdUnNE.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 12,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/page_12_IQeKRWs.jpg",
                    "products": [

                    ]
                },
                {
                    "number": 13,
                    "image_file": "https://interattivo.s3.amazonaws.com/alOjNvUnCW/pages/FINE-coop_jR645nw.png",
                    "products": [

                    ]
                }
            ]
        }
    }

}

const lefletSwitch = () => {
    return leaflets.coop;
}

export const serverResponse = lefletSwitch(); 